export interface IUtilization {
    readonly text: string;
    readonly property: string;
    readonly details: ReadonlyArray<IUtilizationDetail>;
}

export interface IUtilizationDetail {
    readonly image: string;
    readonly text: string;
    readonly property: string;
    readonly hideOnResultsPane?: boolean;
}

export function utilizations(isRebar: boolean | undefined): ReadonlyArray<IUtilization> {
    const rebarOrAnchor = isRebar ? 'Rebar' : 'Anchor';
    return [
        {
            text: 'Agito.Hilti.CW.Utilizations.Tension',
            property: 'tension',
            details: [
                {
                    image: 'tension-steel-anchor',
                    text: `Agito.Hilti.CW.Utilizations.Tension.Steel${rebarOrAnchor}`,
                    property: 'TensileSteelAnchor'
                },
                {
                    image: 'tension-steel-channel-anchor',
                    text: `Agito.Hilti.CW.Utilizations.Tension.SteelChannel${rebarOrAnchor}`,
                    property: 'TensileSteelChannelAnchor'
                },
                {
                    image: 'tension-steel-channel-lip',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.SteelChannelLip',
                    property: 'TensileSteelChannelLip'
                },
                {
                    image: 'tension-concrete-blowout',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.ConcreteBlowout',
                    property: 'TensileConcreteBlowout'
                },
                {
                    image: 'tension-steel-flexure',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.SteelFlexure',
                    property: 'TensileSteelFlexure'
                },
                {
                    image: 'tension-steel-concrete-cone',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.TensileConcreteCone',
                    property: 'TensileConcreteCone'
                },
                {
                    image: 'tension-steel-pullout',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.TensilePullOut',
                    property: 'TensilePullOut'
                },
                {
                    image: 'tension-steel-screw',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.TensileSteelScrew',
                    property: 'TensileSteelScrew'
                },
                {
                    image: 'tension-concrete-kink-splitting',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.KinkSplitting',
                    property: 'TensileKinkSplitting'
                },
                {
                    image: 'tension-steel-anchor',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.AnchorageRebar',
                    property: 'TensileAnchorageRebar'
                },
                {
                    image: 'tension-steel',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.TensionSteel',
                    property: 'TensionSteel'
                },
                {
                    image: 'tension-anchorage-inside',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.TensionAnchorageInside',
                    property: 'TensionAnchorageInside'
                },
                {
                    image: 'tension-concrete-splitting',
                    text: 'Agito.Hilti.CW.Utilizations.Tension.ConcreteSplitting',
                    property: 'TensileConcreteSplitting'
                },
                {
                    image: 'pi-tension-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Steel',
                    property: 'PI-TensionSteel'
                },
                {
                    image: 'pi-tension-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.ConcreteBreakout',
                    property: 'PI-TensionConcreteBreakout'
                },
                {
                    image: 'pi-tension-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.BrickBreakout',
                    property: 'PI-TensionBrickBreakout'
                },
                {
                    image: 'pi-tension-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Pullout',
                    property: 'PI-TensionPullout'
                },
                {
                    image: 'pi-tension-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.PulloutBond',
                    property: 'PI-TensionPulloutBond'
                },
                {
                    image: 'pi-masonry-tension-brick-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.BrickPullout',
                    property: 'PI-TensionBrickPullout'
                },
                {
                    image: 'pi-tension-combined',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.CombinedPulloutBreakout',
                    property: 'PI-TensionCombinedPulloutBreakout'
                },
                {
                    image: 'pi-tension-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SustainedPullout',
                    property: 'PI-TensionSustainedPullout'
                },
                {
                    image: 'pi-tension-splitting',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Splitting',
                    property: 'PI-TensionSplitting'
                },
                {
                    image: 'pi-tension-blowout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.ConcreteBlowout',
                    property: 'PI-TensionConcreteBlowout'
                },
                {
                    image: 'pi-masonry-tension-bond',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Bond',
                    property: 'PI-TensionBond'
                },
                {
                    image: 'pi-masonry-tension-overall',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Overall',
                    property: 'PI-TensionOverall'
                },
                {
                    image: 'pi-tension-supplementary-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SupplementaryReinforcementSteel',
                    property: 'PI-TensionSupplementaryReinforcementSteel'
                },
                {
                    image: 'pi-tension-supplementary-reinforcement-anchorage',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SupplementaryReinforcementAnchorage',
                    property: 'PI-TensionSupplementaryReinforcementAnchorage'
                },
                {
                    image: 'pi-tension-surface-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SurfaceReinforcementSteel',
                    property: 'PI-TensionSurfaceReinforcementSteel'
                }
            ]
        },
        {
            text: 'Agito.Hilti.CW.Utilizations.Shear',
            property: 'shear',
            details: [
                {
                    image: 'shear-steel-screw',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.SteelScrew',
                    property: 'ShearSteelScrew'
                },
                {
                    image: 'shear-steel-channel-lip-perp',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.SteelChannelLipPerp',
                    property: 'ShearSteelChannelLipPerp'
                },
                {
                    image: 'shear-steel-channel-lip-para',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.SteelChannelLipPara',
                    property: 'ShearSteelChannelLipPara'
                },
                {
                    image: 'shear-steel-anchor-perp',
                    text: `Agito.Hilti.CW.Utilizations.Shear.Steel${rebarOrAnchor}Perp`,
                    property: 'ShearSteelAnchorPerp'
                },
                {
                    image: 'shear-steel-anchor-para',
                    text: `Agito.Hilti.CW.Utilizations.Shear.Steel${rebarOrAnchor}Para`,
                    property: 'ShearSteelAnchorPara'
                },
                {
                    image: 'shear-bending-steel-screw',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.BendingSteelScrew',
                    property: 'ShearBendingSteelScrew'
                },
                {
                    image: 'shear-bending-steel-channel-lip',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.BendingSteelChannelLip',
                    property: 'ShearBendingSteelChannelLip'
                },
                {
                    image: 'shear-steel-channel-anchor-perp',
                    text: `Agito.Hilti.CW.Utilizations.Shear.SteelChannel${rebarOrAnchor}Perp`,
                    property: 'ShearSteelChannelAnchorPerp'
                },
                {
                    image: 'shear-steel-channel-anchor-para',
                    text: `Agito.Hilti.CW.Utilizations.Shear.SteelChannel${rebarOrAnchor}Para`,
                    property: 'ShearSteelChannelAnchorPara'
                },
                {
                    image: 'shear-concrete-pryout-perp',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ConcretePryOutPerp',
                    property: 'ShearConcretePryOutPerp'
                },
                {
                    image: 'shear-concrete-pryout-para',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ConcretePryOutPara',
                    property: 'ShearConcretePryOutPara'
                },
                {
                    image: 'shear-concrete-edge-perp',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ConcreteEdgePerp',
                    property: 'ShearConcreteEdgePerp'
                },
                {
                    image: 'shear-concrete-edge-para',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ConcreteEdgePara',
                    property: 'ShearConcreteEdgePara'
                },
                {
                    image: 'shear-steel-rebar',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ShearSteelRebar',
                    property: 'ShearSteelRebar'
                },
                {
                    image: 'shear-steel-rebar',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ShearAnchorageRebar',
                    property: 'ShearAnchorageRebar'
                },
                {
                    image: 'shear-crack-width-side-sls',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ShearCrackWidthSideSls',
                    property: 'ShearCrackWidthSLS'
                },
                {
                    image: 'shear-steel-perp',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ShearSteelPerp',
                    property: 'ShearSteelPerp'
                },
                {
                    image: 'shear-anchorage-inside-perp',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ShearAnchorageInsidePerp',
                    property: 'ShearAnchorageInsidePerp'
                },
                {
                    image: 'shear-steel-para',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ShearSteelPara',
                    property: 'ShearSteelPara'
                },
                {
                    image: 'shear-anchorage-inside-para',
                    text: 'Agito.Hilti.CW.Utilizations.Shear.ShearAnchorageInsidePara',
                    property: 'ShearAnchorageInsidePara'
                },
                {
                    image: 'pi-shear-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Steel',
                    property: 'PI-ShearSteel'
                },
                {
                    image: 'pi-shear-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteBvc',
                    property: 'PI-ShearConcreteBvc'
                },
                {
                    image: 'pi-shear-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteEdgeBreakout',
                    property: 'PI-ShearConcreteEdgeBreakout'
                },
                {
                    image: 'pi-shear-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteEdgeBreakoutParallel',
                    property: 'PI-ShearConcreteEdgeBreakoutParallel'
                },
                {
                    image: 'pi-shear-pryout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Pryout',
                    property: 'PI-ShearPryout'
                },
                {
                    image: 'pi-shear-concrete-bearing',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BearingStrength',
                    property: 'PI-ShearBearingStrength'
                },
                                {
                    image: 'pi-shear-additive-concrete-bearing',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.StressLimit',
                    property: 'PI-ShearStressLimit'
                },
                {
                    image: 'pi-shear-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BrickEdge',
                    property: 'PI-ShearBrickEdge'
                },
                {
                    image: 'pi-masonry-shear-local-brick',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BrickLocal',
                    property: 'PI-ShearBrickLocal'
                },
                {
                    image: 'pi-masonry-shear-brick-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BrickPushout',
                    property: 'PI-ShearBrickPushout'
                },
                {
                    image: 'pi-masonry-shear-bond',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Bond',
                    property: 'PI-ShearBond'
                },
                {
                    image: 'pi-masonry-shear-overall',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Overall',
                    property: 'PI-ShearOverall'
                },
                {
                    image: 'pi-shear-supplementary-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.SupplementaryReinforcementSteel',
                    property: 'PI-ShearSupplementaryReinforcementSteel'
                },
                {
                    image: 'pi-shear-supplementary-reinforcement-anchorage',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.SupplementaryReinforcementAnchorage',
                    property: 'PI-ShearSupplementaryReinforcementAnchorage'
                },
                {
                    image: 'pi-shear-surface-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.SurfaceReinforcementSteel',
                    property: 'PI-ShearSurfaceReinforcementSteel'
                },
                {
                    image: 'pi-masonry-shear-bond',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.AnchorageStrength',
                    property: 'PI-ShearAnchorageStrength'
                },
                {
                    image: 'pi-masonry-crushing',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.MasonryCrushing',
                    property: 'PI-ShearMasonryCrushing'
                }
            ]
        },
        {
            text: 'Agito.Hilti.CW.Utilizations.Combination',
            property: 'combination',
            details: [
                {
                    image: 'combination-screw',
                    text: 'Agito.Hilti.CW.Utilizations.Combination.Screw',
                    property: 'InteractionScrew'
                },
                {
                    image: 'combination-channel-anchor',
                    text: `Agito.Hilti.CW.Utilizations.Combination.Channel${rebarOrAnchor}`,
                    property: 'InteractionChannelAnchor'
                },
                {
                    image: 'combination-channel-lip',
                    text: 'Agito.Hilti.CW.Utilizations.Combination.ChannelLip',
                    property: 'InteractionChannelLip'
                },
                {
                    image: 'combination-channel-lip-flexure',
                    text: 'Agito.Hilti.CW.Utilizations.Combination.ChannelLipFlexure',
                    property: 'InteractionChannelLipFlexure'
                },
                {
                    image: 'combination-concrete',
                    text: 'Agito.Hilti.CW.Utilizations.Combination.Concrete',
                    property: 'InteractionConcrete'
                },
                {
                    image: 'pi-combination-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Combination.Steel',
                    property: 'PI-CombinationSteel'
                },
                {
                    image: 'pi-combination-concrete',
                    text: 'Agito.Hilti.Profis3.Utilizations.Combination.Concrete',
                    property: 'PI-CombinationConcrete'
                },
                {
                    hideOnResultsPane: true,
                    image: '',
                    text: '',
                    property: 'PI-CombinationDecisive'
                }
            ]
        }
    ];
}

export function concreteCompressionForceUtilizations(): ReadonlyArray<IUtilization> {
    return [
        {
            text: 'Agito.Hilti.CW.Utilizations.ConcreteCompressionForces',
            property: 'concrete-compression-force',
            details: [
                {
                    image: 'concrete-compression-force',
                    text: 'Agito.Hilti.CW.Utilizations.ConcreteCompressionForces.Fc.Plate1',
                    property: 'ResultingCompression'
                }
            ]
        },
        {
            text: 'Agito.Hilti.CW.Utilizations.ConcreteCompressionForces',
            property: 'concrete-compression-force',
            details: [
                {
                    image: 'concrete-compression-force',
                    text: 'Agito.Hilti.CW.Utilizations.ConcreteCompressionForces.Fc.Plate2',
                    property: 'ResultingCompression2'
                }
            ]
        }
    ];
}
