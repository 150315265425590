import { DesignStandards } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';

export class DesignStandardHelper {

    public static isHnaBasedDesignStandard(value?: number) {
        switch (value) {
            case DesignStandards.ACI:
            case DesignStandards.CSA:
                return true;

            default:
                return false;
        }
    }

    public static isEnBasedDesignStandard(value?: number) {
        switch (value) {
            case DesignStandards.ETAG:
            case DesignStandards.SATS:
            case DesignStandards.HK:
                return true;
            default:
                return false;
        }
    }
}