import sortBy from 'lodash-es/sortBy';

import {
    AfterViewInit,
    Component, ElementRef, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation
} from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';
import { Design } from '../../entities/design';
import {
    IExportReportProjectDetailsInput
} from '@profis-engineering/pe-ui-common/entities/export-report-project-details';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { NgForm } from '@angular/forms';
import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { Feature } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { CalculationService } from '../../services/calculation.service';
import { UnitService } from '../../services/unit.service';
import { Sprite, includeSprites } from '../../sprites';
import {
    IExportReportCompanyLayoutComponentInput
} from '@profis-engineering/pe-ui-common/entities/export-report-company-layout';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { DesignCodeList } from '../../entities/enums/design-code-list';
import { PropertyMetaData } from '../../entities/properties';
import { AnchorChannelEmbedmentDepth } from '../../entities/code-lists/anchor-channel-embedment-depth';
import { ReportDetails } from '../../entities/report-details';
import { ReportOptionsEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Calculation';
import moment from 'moment';
import { UserSettingsService } from '../../services/user-settings.service';
import { DateTimeService } from '../../services/date-time.service';
import { environment } from '../../../environments/environmentCW';
import { Constants } from '../../entities/constants';
import { StandoffTypes } from '../../entities/code-lists/standoff-types';
import { BoltLength } from '../../entities/code-lists/bolt-length';
import { ScreenshotService } from '../../services/screenshot.service';
import { isAnchorRebar } from '../../services/helpers/static-menu-helper';

import { PaperSize, ReportType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    ReportType as ReportTypeCodeList
} from '@profis-engineering/pe-ui-common/entities/code-lists/report-type';
import { NumberService } from '../../services/number.service';
import {
    CodeList,
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    ReportPaperSize,
    ReportPaperSize as ReportPaperSizeCodeList
} from '@profis-engineering/pe-ui-common/entities/code-lists/report-paper-size';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import { LanguageCulture } from '@profis-engineering/pe-ui-common/services/localization.common';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ReportTemplateService } from '../../services/report-template.service';
import { ReportTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { ModalService } from '../../services/modal.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import {
    IExportTrimbleConnectComponentInput
} from '@profis-engineering/pe-ui-common/entities/export-trimble-connect';
import { BrowserService } from '../../services/browser.service';
import { DesignHelper } from '../../helpers/design-helper';
import { EmbedmentDepthOptimizationType, LoadCombinationsCalculateTypes } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { LoadCombinationEntity } from '../../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { LegendImageGenerator } from '@profis-engineering/pe-ui-common/helpers/legend-image-generator';
import { LoadsLegendType } from '@profis-engineering/pe-ui-common/components/loads-legend/loads-legend.common';
import loadIconBlueImage from '../../bundles/main/load-icon-blue.png';
import loadIconLoadInWorstPositionImage from '../../bundles/main/load-icon-load-in-worst-position.png';
import loadIconOrangeImage from '../../bundles/main/load-icon-orange.png';
import loadIconImage from '../../bundles/main/load-icon.png';
import { PropertiesHelper } from '../../helpers/properties-helper';
import { DesignStandardHelper } from '../../helpers/design-standard-helper';
import { CodelistHelper } from '../../helpers/codelist-helper';

const customPicturesMaxUploadSize: number = 2 * 1024 * 1024;
const customTemplateId = Number.MAX_VALUE;

interface ISectionCollapse {
    summary: boolean;
    projectDetails: boolean;
    commentAndNotes: boolean;
    layout: boolean;
    customPicturesAvailable: boolean;
    calculations: boolean;
    trimbleConnect: boolean;
}

enum ValueWithLengthFormatMode {
    Default = 0,
    Hef = 1,
    Eb = 2,
    L = 3
}

@Component({
    templateUrl: './report-export.component.html',
    styleUrls: ['./report-export.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ReportExportComponent implements OnInit, AfterViewInit {

    public cwDesign?: Design;

    @Input()
    public modalInstance!: ModalInstance<Set<IDisplayDesign>>;

    @ViewChild('modelImage')
    modelImageElement!: ElementRef;

    @ViewChildren('customPicturesInput')
    customPicturesInputs!: QueryList<ElementRef>;

    public propertyMetaData = PropertyMetaData;
    public isLoaded = false;
    public submitted = false;
    public isSectionCollapsed!: ISectionCollapse;

    // summary
    public baseMaterialStructure!: string;
    public evaluationService!: string;
    public designMethod!: string;
    public anchorImage!: string;
    public anchorType!: string[] | string;
    public boltImage!: string;
    public bolt!: string;
    public issued!: string;
    public embedmentDepthValue!: string;
    public standoffInstallationValue!: string;
    public iccApproval!: string;

    // project details
    public exportReportProjectDetailsInputs!: IExportReportProjectDetailsInput;

    // calculations
    public devSpliceLengthCalculationsCheckbox!: CheckboxButtonProps<boolean>;
    public yieldLengthCalculationsCheckbox!: CheckboxButtonProps<boolean>;
    public anchorageCalculationsCheckbox!: CheckboxButtonProps<boolean>;
    public calculationSettingsDisabled!: boolean;

    // comment-and-notes
    public noteLoadCaseResultingAnchorForces!: string;
    public noteTensionLoad!: string;
    public noteShearLoad!: string;
    public noteCombinedTensionAndShearLoad!: string;
    public noteDisplacements!: string;
    public noteInstallationData!: string;

    // section-pictures
    public customPictures: Map<number, { imgUrl: string; imgSize: number }> = new Map<number, { imgUrl: string; imgSize: number }>();
    private customPicturesUploadSize = customPicturesMaxUploadSize;
    public customPicturesChanged = false;
    public customPictureIds!: string[];

    // company layout
    public exportReportCompanyLayoutInputs!: IExportReportCompanyLayoutComponentInput;

    // trimble-connect
    public exportTrimbleConnectInput!: IExportTrimbleConnectComponentInput;

    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        private browserService: BrowserService,
        private userService: UserService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private modalService: ModalService,
        private unitService: UnitService,
        private calculationService: CalculationService,
        private userSettingsService: UserSettingsService,
        private dateTimeService: DateTimeService,
        public screenshotService: ScreenshotService,
        private commonCodeList: CommonCodeListService,
        private numberService: NumberService,
        private trimbleConnectService: TrimbleConnectService,
        private reportTemplateService: ReportTemplateService
    ) { }

    public get title() {
        return this.translate('Agito.Hilti.CW.ExportReport.Title');
    }

    public get anchoringTypeTranslation() {
        return this.isPostInstallAnchorProduct() ? this.translate('Agito.Hilti.CW.ExportReport.Summary.AnchorType') : this.translate('Agito.Hilti.CW.ExportReport.Summary.AnchoringType');
    }

    public get boltTranslation() {
        return this.translate('Agito.Hilti.CW.ExportReport.Summary.Bolt');
    }

    public get baseMaterialTranslation() {
        return this.translate('Agito.Hilti.CW.ExportReport.Summary.BaseMaterialStructure');
    }

    public get iccApprovalTranslation() {
        const approvalType = DesignStandardHelper.isHnaBasedDesignStandard(this.design?.designStandard?.id)
            ? 'IccApproval'
            : 'EuropeanTechnicalAssessment';

        return this.translate(`Agito.Hilti.CW.ExportReport.Summary.${approvalType}`);
    }

    public get issuedTranslation() {
        return this.translate('Agito.Hilti.CW.ExportReport.Summary.Issued');
    }

    public get effectiveEmbedementDepthTranslation() {
        return isAnchorRebar(this.design)
            ? this.translate('Agito.Hilti.CW.ExportReport.RebarLength')
            : this.translate('Agito.Hilti.CW.ExportReport.Summary.EffectiveEmbedmentDepth');
    }

    public get standoffInstallationTranslation() {
        return this.translate('Agito.Hilti.CW.ExportReport.Summary.StandoffInstallation');
    }

    public get designMethodTranslation() {
        return this.translate('Agito.Hilti.CW.ExportReport.Summary.DesignMethod');
    }

    public get notAvailableTranslation() {
        return this.translate('Agito.Hilti.CW.ExportDesign.NotAvailable');
    }

    public get hiltiTechnicalDataTranslation() {
        return this.translate('Agito.Hilti.CW.ExportDesign.HiltiTechnicalData');
    }

    public get isLoadCombinationDropdownVisible() {
        return this.design.isMultipleLoadCombinations;
    }

    public get disableCustomImages() {
        return this.userService.hasfloatingLimitReached || this.userService.hasFreeLicense || this.userService.hasOnlyBasic;
    }

    public get disableTrimbleConnect() {
        return !this.trimbleConnectService.isEnabled;
    }

    public get design() {
        return this.userService.design;
    }

    public isPostInstallAnchorProduct() {
        return this.design.isPostInstallAnchorProduct();
    }

    private get customPicturesInput1() {
        return this.customPicturesInputs.get(0)?.nativeElement as HTMLInputElement;
    }

    private get customPicturesInput2() {
        return this.customPicturesInputs.get(1)?.nativeElement as HTMLInputElement;
    }

    private get customPicturesInput3() {
        return this.customPicturesInputs.get(2)?.nativeElement as HTMLInputElement;
    }

    public get reportDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_Report, this.design.region.id);
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines-expanded',
            'sprite-lines',
            'sprite-x',
            'sprite-no-photo-available'
        );

        this.exportReportProjectDetailsInputs = {
            designName: this.userService.project.getDisplayName(this.localizationService) + ', ' + this.design.designName,
            fasteningPoint: this.design.designData.projectDesign?.options.reportDialog.fasteningPoint ?? '',
            fasteningPointTitle: this.translate('Agito.Hilti.Profis3.ExportReport.ProjectDetails.FasteningPoint'),
            fasteningPointId: 'export-report-details-section-rebarapplication-textbox',
            notes: this.design.designData.projectDesign?.options.reportDialog.notes ?? '',
            reportPaperSize: this.design.designData.projectDesign?.options.reportDialog.reportPaperSize ?? PaperSize.A4,
            reportType: this.design.designData.projectDesign?.options.reportDialog.reportType ?? ReportType.Detailed,
            reportDisabled: this.reportDisabled,
            includeDetailsInReport: {
                items: [],
                title: 'Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeDesign',
                selectedValues: new Set()
            }
        } as IExportReportProjectDetailsInput;

        this.isSectionCollapsed = {
            summary: false,
            commentAndNotes: true,
            layout: true,
            projectDetails: true,
            customPicturesAvailable: true,
            calculations: true,
            trimbleConnect: true
        };

        this.customPicturesUploadSize = customPicturesMaxUploadSize;
        this.customPicturesChanged = false;

        this.customPictureIds = [];

        this.exportTrimbleConnectInput = {
            trimbleConnectChecked: this.design.designData.projectDesign?.options.reportDialog.trimbleConnectUpload ?? false,
            trimbleConnectLocation: this.design.designData.projectDesign?.options.reportDialog.trimbleConnectLocation ?? '',
            trimbleConnectReportName: this.design.designData.projectDesign?.options.reportDialog.trimbleConnectReportName ?? '',
            trimbleConnectFolderId: this.design.designData.projectDesign?.options.reportDialog.trimbleConnectFolderId ?? '',
            trimbleConnectTooltip: this.reportExportDisabledTooltip(),
            isOfflineOnLine: this.browserService.isOfflineOnLine
        } as IExportTrimbleConnectComponentInput;

        this.setCustomPictures();

        this.setIncludeDetailsItems();

        this.setEmbedmentDepthValue();
        this.setStandoffInstallationValue();
        this.setAnchor();
        this.setAnchorImage();
        this.setBolt();
        this.setBoltImage();
        this.setBaseMaterialStructure();
        this.setApprovalValues();
        this.setDesignMethod();
        this.setInitialCheckboxStates();
        this.isLoaded = true;

        this.setExportReportCompanyLayoutInputs();
        this.setExportReportCustomCommentInputs();
    }

    private getLoadsForDropdown(design: Design): LoadCombinationEntity[] {
        if (design.loadsCalculationMode == LoadCombinationsCalculateTypes.Selected) {
            const selectedLoadCombination = design.loads.find((loadCombination) => loadCombination.id == this.design.selectedLoadCombinationId);
            return selectedLoadCombination != undefined ? [selectedLoadCombination] : [];
        }

        return design.loads;
    }

    public setExportReportCompanyLayoutInputs() {
        const selectedReportTypeId = this.reportTypes.find(reportType => reportType.id === this.design.designData.projectDesign?.options.reportDialog.reportType as number)?.id;

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const reportTypesItems = this.reportTypes;

        const decisiveLoadCombination = this.design.decisiveLoadCombination;

        const loads = this.getLoadsForDropdown(this.design);

        const defaultLanguageId = this.userSettingsService.settings.application.general.languageId.value;
        const selectedLanguage = this.languages.find((language) => language.id === this.design.designData.projectDesign?.options.reportDialog.reportLanguageId)?.id ?? defaultLanguageId;

        const defaultPaperSize = this.userSettingsService.settings.application.general.regionId.value == 6 ? PaperSize.Letter : PaperSize.A4;
        const selectedReportPaperSize = this.reportPaperSizes.find((reportPaperSize) => reportPaperSize.id === this.design.designData.projectDesign?.options.reportDialog.reportPaperSize)?.id ?? defaultPaperSize;

        this.exportReportCompanyLayoutInputs = {
            reportTemplate: this.reportTemplate,
            reportTemplateDisabled: this.reportTemplateDisabled,
            isLoadCombinationDropdownVisible: this.isLoadCombinationDropdownVisible,
            displayLoadCaseDropdown: false,
            isExternalOnlineRussianUser: this.userService.isExternalOnlineRussianUser,
            handrailSafetyDesign: false,
            firstPageNumber: this.design.designData.projectDesign?.options.reportDialog.firstPageNumber ?? 1,
            reportCompanyName: this.design.designData.projectDesign?.options.reportDialog.reportCompanyName ?? '',
            reportAddress: this.design.designData.projectDesign?.options.reportDialog.reportAddress ?? '',
            reportContactPerson: this.design.designData.projectDesign?.options.reportDialog.reportContactPerson ?? '',
            reportPhoneNumber: this.design.designData.projectDesign?.options.reportDialog.reportPhoneNumber ?? '',
            reportEmail: this.design.designData.projectDesign?.options.reportDialog.reportEmail ?? '',
            reportTypeDropdown: this.getReportTypeDropdown(reportTypesItems, selectedReportTypeId ?? ReportType.Detailed, 'export-report-layout-section-report-type-dropdown'),
            languageDropdown: {
                id: 'export-report-layout-section-language-dropdown',
                items: this.languages.map((language) => {
                    return {
                        value: language.id,
                        text: language.getTranslatedNameText(codeListDeps)
                    } as DropdownItem<number>;
                }),
                selectedValue: selectedLanguage,
            },
            paperSizeDropdown: {
                id: 'export-report-layout-section-papersize-dropdown',
                items: this.reportPaperSizes.map((reportPaperSize) => {
                    return {
                        value: reportPaperSize.id,
                        text: reportPaperSize.getTranslatedNameText(codeListDeps)
                    } as DropdownItem<number>;
                }),
                selectedValue: selectedReportPaperSize,
            },
            loadCombinationDropdown: {
                id: 'export-report-load-combination-dropdown',
                tooltip: this.localizationService.getString('Agito.Hilti.CW.ExportReport.Layout.LoadCombination.Tooltip'),
                items: loads.map((loadCombination) => {
                    return {
                        value: loadCombination.id,
                        text: (decisiveLoadCombination === loadCombination.id ? '* ' : '') + loadCombination.name
                    } as DropdownItem<string>;
                }),
                selectedValue: this.design.loads.find((loadCombination) => loadCombination.id == this.design.selectedLoadCombinationId)?.id
            },
            loadCaseDropdown: {},
            loadCaseHandrailDropdown: {},
            templateDropdown: {
                id: 'export-report-layout-section-template-dropdown',
                items: [],
                selectedValue: this.reportTemplate?.Id ?? customTemplateId,
            }
        } as IExportReportCompanyLayoutComponentInput;
    }

    public setExportReportCustomCommentInputs() {
            this.noteLoadCaseResultingAnchorForces = this.design.designData.projectDesign?.options.reportDialog.noteLoadCaseResultingRebarForces ?? '';
            this.noteTensionLoad = this.design.designData.projectDesign?.options.reportDialog.noteTensionLoad ?? '';
            this.noteShearLoad = this.design.designData.projectDesign?.options.reportDialog.noteShearLoad ?? '';
            this.noteCombinedTensionAndShearLoad = this.design.designData.projectDesign?.options.reportDialog.noteCombinedTensionAndShearLoads ?? '';
            this.noteDisplacements = this.design.designData.projectDesign?.options.reportDialog.noteDisplacements ?? '';
            this.noteInstallationData = this.design.designData.projectDesign?.options.reportDialog.noteInstallationData ?? '';
    }

    ngAfterViewInit(): void {
        this.setDesign3dImage();
    }

    public async save(form: NgForm) {
        const invalidChars = new RegExp(/[\^{}_]/g);
        if ([
            this.noteLoadCaseResultingAnchorForces, this.noteTensionLoad, this.noteShearLoad,
            this.noteCombinedTensionAndShearLoad, this.noteDisplacements, this.noteInstallationData,
            this.exportReportProjectDetailsInputs.notes, this.exportReportProjectDetailsInputs.fasteningPoint
        ].some(s => invalidChars.test(s as string))) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.Warning'),
                this.localizationService.getString('Agito.Hilti.Profis3.InvalidCharacters.Message')
            );
            return;
        }

        if (this.submitted || !form.valid) {
            return;
        }

        this.submitted = true;

        if (this.isPostInstallAnchorProduct() && this.customPictures.size > 0 && this.customPicturesChanged) {
            await this.uploadPictures()
                .then(async response => {
                    if (this.customPictures.size != response.length) {
                        this.modalService.openAlertWarning(
                            this.translate('Agito.Hilti.Profis3.ExportReport.Pictures.UploadIssuesPopup.Title'),
                            this.translate('Agito.Hilti.Profis3.ExportReport.Pictures.UploadIssuesPopup.Body')
                        );
                        return;
                    }

                    this.customPictureIds = response;

                    // Generate report
                    await this.generateAndDownloadReportCW();
                });
        }
        else {
            // Generate report without custom images
            await this.generateAndDownloadReportCW();
        }

        this.submitted = false;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private get reportTemplate() {
        return this.design.projectDesign.options.reportDialog.reportTemplateId === -1 ?
            this.reportTemplateService.templates.find((template) => template.Name === 'Default') :
            this.reportTemplateService.templates.find((template) => template.Id === this.design.projectDesign.options.reportDialog.reportTemplateId);
    }

    public getArrowClass(isCollapsed: boolean) {
        return isCollapsed ? 'pe-ui-cw-sprite-lines-expanded' : 'pe-ui-cw-sprite-lines';
    }

    private get reportTemplateDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_ReportTemplate, this.design.region.id);
    }

    public reportExportDisabledTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_Report);
    }

    public get reportTypes(): ReportTypeCodeList[] {
        return this.commonCodeList.commonCodeLists[CommonCodeList.ReportType] as ReportTypeCodeList[];
    }

    public get reportPaperSizes() {
        return this.commonCodeList.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSizeCodeList[];
    }

    private getReportTypeDropdown(items: CodeList[], selectedValue: number, id: string): DropdownProps<number> {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return {
            id,
            items: items.map(item => {
                return {
                    value: item.id,
                    text: item.getTranslatedNameText(codeListDeps) as string
                };
            }),
            selectedValue
        };
    }

    public reportTemplateDisabledTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_ReportTemplate);
    }

    public get formatedReportType() {
        return this.formatReportType(this.templateReportType());
    }

    public formatReportType(reportTypeId: ReportType) {
        const reportTypes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportType] as ReportTypeCodeList[];
        const reportType = reportTypes.find((item) => item.id === reportTypeId);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.ReportTypeFormat').replace('{reportType}', reportType?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public templateReportType(): ReportType {
        return this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType ?? ReportType.Detailed;
    }

    public get formatedPaperSize() {
        const paperSize = this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize;
        return paperSize ? this.formatPaperSize(paperSize) : undefined;
    }

    public formatPaperSize(paperSizeId: PaperSize) {
        const reportPaperSizes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSize[];
        const paperSize = reportPaperSizes.find((item) => item.id === paperSizeId);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.PaperSizeFormat').replace('{paperSize}', paperSize?.getTranslatedNameText(codeListDeps) ?? '');
    }

    private get languages() {
        const languages = (this.commonCodeList.commonCodeLists[CommonCodeList.Language] as Language[]).filter(language => language.culture != LanguageCulture.pseudoLanguage);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return sortBy(languages, (reportLanguage) => (reportLanguage.getTranslatedNameText(codeListDeps) ?? '').toLowerCase());
    }

    public close() {
        this.modalInstance.close();
    }

    public onReportTypeChange(reportTemplate: ReportTemplateEntity | undefined) {
        this.exportReportCompanyLayoutInputs.reportTemplate = reportTemplate;
    }

    public async setDesign3dImage() {
        this.submitted = true;

        this.design.screenshots.reportDialog = await this.screenshotService.create3dPrintPreview();
        if (this.modelImageElement) {
            (this.modelImageElement.nativeElement as HTMLImageElement).src = this.design.screenshots.reportDialog ?? '';
        }

        const applicationType = this.design.designData.projectDesign?.options.applicationType;
        const basePlateSystems = this.design.selectedAnchoringSystem.basePlateSystems;

        this.design.screenshots.report3dGeometry = await this.screenshotService.create3dGeometry();

        const reportFixureImages: (string | undefined)[] = [];
        for (const basePlateSystem of basePlateSystems) {
            reportFixureImages.push(await this.screenshotService.create3dFixtureGroup(basePlateSystem.id, applicationType));
        }
        this.design.screenshots.report3dFixtures = reportFixureImages;

        this.submitted = false;
    }

    private setEmbedmentDepthValue() {
        const { isUserDefined, effectiveEmbedmentDepth, valueWithLengthFormatMode } = this.isPostInstallAnchorProduct() ? this.setPostInstallEmbedmentDepth() : this.setAnchorChannelEmbedmentDepth();
        const formattedEmbedmentDepthValue = effectiveEmbedmentDepth ? this.getValueWithLengthFormat(effectiveEmbedmentDepth, valueWithLengthFormatMode) : this.notAvailableTranslation;

        const userDefinedString = isUserDefined ? `${this.translate('Agito.Hilti.CW.ExportReport.UserDefined')}, ` : '';
        this.embedmentDepthValue = `${userDefinedString}${formattedEmbedmentDepthValue}`;
    }

    private setAnchorChannelEmbedmentDepth() {
        const effectiveEmbedmentDepths = (CodelistHelper.getCodeListItems(this.design, DesignCodeList.AnchorChannelEmbedmentDepth, PropertyMetaData.AnchorChannel_CW_EmbedmentDepth.id)) as AnchorChannelEmbedmentDepth[];
        const valueWithLengthFormatMode = (isAnchorRebar(this.design)) ? ValueWithLengthFormatMode.L : ValueWithLengthFormatMode.Hef;

        const effectiveEmbedmentDepth = (isAnchorRebar(this.design)) ?
            effectiveEmbedmentDepths.find(it => it.id == this.design.designData.projectDesign?.product.anchorChannel.embedmentDepthId)?.rebarChannel?.l :
            effectiveEmbedmentDepths.find(it => it.id == this.design.designData.projectDesign?.product.anchorChannel.embedmentDepthId)?.h_ef;

        const isUserDefined = effectiveEmbedmentDepths.find(it => it.id == this.design.designData.projectDesign?.product.anchorChannel.embedmentDepthId)?.id == -1;

        return {
            isUserDefined,
            effectiveEmbedmentDepth,
            valueWithLengthFormatMode
        };
    }

    private setPostInstallEmbedmentDepth() {
        const postInstallDesign = this.design.designData.projectDesign?.product.postInstalledAnchor;
        const valueWithLengthFormatMode = ValueWithLengthFormatMode.Hef;
        const isUserDefined = postInstallDesign.embedmentDepthOptimizationType === EmbedmentDepthOptimizationType.UserSelected;
        const effectiveEmbedmentDepth = postInstallDesign.embedmentDepth;

        return {
            isUserDefined,
            effectiveEmbedmentDepth,
            valueWithLengthFormatMode
        };
    }

    public setStandoffInstallationValue() {
        const standoffTypes = (CodelistHelper.getCodeListItems(this.design, DesignCodeList.StandoffTypes, PropertyMetaData.Plate_CW_Standoff_Type.id)) as StandoffTypes[];
        const typeTranslationKey = standoffTypes.find(it => it.id == this.design.designData.projectDesign?.basePlate.standoff.type)?.nameResourceKey ?? '';
        const type = this.translate(typeTranslationKey);

        const distance = this.design.designData.projectDesign?.basePlate.standoff.type == 0 ? 0 : this.design.designData.projectDesign?.basePlate.standoff.distance;
        const value = this.getValueWithLengthFormat(distance, ValueWithLengthFormatMode.Eb);

        this.standoffInstallationValue = `${value} (${type})`;
    }

    private getValueWithLengthFormat(value: number | undefined, mode = ValueWithLengthFormatMode.Default) {
        if (value == undefined)
            return this.notAvailableTranslation;

        const formattedValue = this.getValueWithUnit(value, UnitGroup.Length);

        switch (mode) {
            case ValueWithLengthFormatMode.Hef:
                return `h_ef = ${formattedValue}`;
            case ValueWithLengthFormatMode.Eb:
                return `eb = ${formattedValue}`;
            case ValueWithLengthFormatMode.L:
                return `l = ${formattedValue}`;
            default:
                return `${formattedValue}`;
        }
    }

    private setApprovalValues() {
        const approvals = this.design.reportData?.approvals ?? [];

        let approvalNumber = '';
        let issuedValid = '';
        let approvalSeparator = '';

        approvals.forEach((approval) => {
            approvalNumber += approvalSeparator + approval?.apprNumber ?? this.notAvailableTranslation;
            const issued = this.formatDate(approval.apprIssued);
            const valid = this.formatDate(approval.apprValidity);
            issuedValid += approvalSeparator + issued + ' - ' + valid;
            approvalSeparator = ', ';
        });

        this.iccApproval = approvalNumber != '' ? approvalNumber : this.hiltiTechnicalDataTranslation;
        this.issued = issuedValid != '' ? issuedValid : this.notAvailableTranslation;
    }

    private formatDate(value?: Date): string {
        if (value == undefined)
            return '';

        const region = this.userSettingsService.getRegionById(this.userSettingsService.settings.application.general.regionId.value ?? 0);
        const dotNetFormat = region.dateTimeFormat?.ShortDatePattern as string;
        const momentFormat = this.dateTimeService.dotNetToMomentFormat(dotNetFormat);
        return moment.utc(value).locale(region.culture).format(momentFormat);
    }

    private setDesignMethod() {
        this.designMethod = this.translate('Agito.Hilti.CW.DesignMethod.' + this.design.designMethodGroup?.displayKey);
        const longitudinalShearCalculationType = this.design.longitudinalShearCalculationType;

        if (PropertiesHelper.IsLongitudinalShearCalculationApplicableInDesignMethod(this.design) &&
            longitudinalShearCalculationType != null && longitudinalShearCalculationType != '') {
            this.designMethod += ' + ' + longitudinalShearCalculationType;
        }
    }

    public setAnchorImage() {
        let image: string | undefined = undefined;

        if (this.isPostInstallAnchorProduct())
            image = this.design.anchorFamily?.image;
        else if (DesignHelper.isTopView(this.design.applicationType))
            image = this.design.anchorChannelFamily?.image;
        else
            image = this.design.anchorChannelFamily?.imageFoS;

        this.anchorImage = 'sprite-' + (image ?? '');

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            this.anchorImage as Sprite
        );
    }

    private setBoltImage() {
        this.boltImage = 'sprite-' + this.design.boltFamily?.image ?? '';

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            this.boltImage as Sprite
        );
    }

    private setInitialCheckboxStates() {
        this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.IncludeItemNumbers);
    }

    public setBaseMaterialStructure() {
        if (this.design.designData.projectDesign?.baseMaterial.crackedConcrete)
            this.baseMaterialStructure = `${this.translate('Agito.Hilti.CW.Concrete.CrackedConcrete')}`;
        else
            this.baseMaterialStructure = `${this.translate('Agito.Hilti.CW.Concrete.UncrackedConcrete')}`;

        if (this.design.designData.projectDesign?.baseMaterial.material == Constants.UserDefinedId) {
            this.baseMaterialStructure += `, ${this.translate('Agito.Hilti.CW.CodeList.BaseMaterialStructureEntity.Custom')}`;
        }

        const material = this.getValueWithUnit(this.design.designData.projectDesign?.baseMaterial.fcCyl, UnitGroup.Stress);

        this.baseMaterialStructure += `, f'c = ${material}`;
    }

    public getValueWithUnit(value: number, unitGroup: UnitGroup) {
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultUnitValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit);
        const precision = this.unitService.getPrecision(defaultUnit);
        const formattedValue = this.unitService.formatUnitValueArgs(defaultUnitValue, defaultUnit, precision);

        return `${formattedValue}`;
    }

    public setAnchor() {
        this.anchorType = this.isPostInstallAnchorProduct()
            ? [this.design?.productName ?? this.notAvailableTranslation]
            : this.design.designData.reportData?.anchorChannelName ?? this.notAvailableTranslation;
    }

    public setBolt() {
        if (this.design.designData.reportData?.boltName == null) {
            this.bolt = this.notAvailableTranslation;
            return;
        }

        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);

        const getBoltLengths = (CodelistHelper.getCodeListItems(this.design, DesignCodeList.BoltLengths, PropertyMetaData.Bolt_CW_Length.id)) as BoltLength[];

        const boltLength = getBoltLengths.find(it => it.id == this.design.designData.projectDesign.basePlate.bolt.lengthId)?.ls;
        const boltLengthAsInternal = this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(boltLength as number, internalUnit, internalUnit), Unit.mm);

        const shouldShowInternalBoltLength = defaultUnit != Unit.mm && !this.design.designData.projectDesign.basePlate.bolt.lengthNotApplicable;

        this.bolt = shouldShowInternalBoltLength
            ? `${this.design.designData.reportData?.boltName} (${boltLengthAsInternal})`
            : this.design.designData.reportData?.boltName;
    }

    private getReportOptions(): ReportOptionsEntity {
        return {
            fasteningPoint: this.exportReportProjectDetailsInputs.fasteningPoint,
            notes: this.exportReportProjectDetailsInputs.notes,
            companyName: this.reportTemplate?.Company ?? this.exportReportCompanyLayoutInputs.reportCompanyName,
            contactPerson: this.reportTemplate?.ContactPerson ?? this.exportReportCompanyLayoutInputs.reportContactPerson,
            phoneNumber: this.reportTemplate?.PhoneNumber ?? this.exportReportCompanyLayoutInputs.reportPhoneNumber,
            email: this.reportTemplate?.Email ?? this.exportReportCompanyLayoutInputs.reportEmail,
            reportLanguageId: this.getSelectedLanguageId(),
            firstPage: this.exportReportCompanyLayoutInputs.firstPageNumber,
            address: this.reportTemplate?.Address ?? this.exportReportCompanyLayoutInputs.reportAddress,
            includeItemNumber: this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.IncludeItemNumbers) ?? false,
            includeApprovals: this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.IncludeApprovals) ?? false,
            appVersionUI: environment.moduleVersion,
            reportTemplateId: this.design.projectDesign.options.reportDialog.reportTemplateId,
            selectedLoadCombinationId: this.exportReportCompanyLayoutInputs.loadCombinationDropdown.selectedValue ?? '',
            images: {
                geometry: this.design.screenshots.report3dGeometry,
                fixtureGroup1: this.design.screenshots.report3dFixtures?.[0] ?? '',
                fixtureGroup2: this.design.screenshots.report3dFixtures?.[1] ?? '',
                loadsLegend: this.design.screenshots.reportLoadsLegend
            },
            decimalNumberSeparator: this.userSettingsService.getDecimalSeparator().character,
            thousandsNumberSeparator: this.userSettingsService.getThousandsSeparator().character,
            noteCombinedTensionAndShearLoad: this.noteCombinedTensionAndShearLoad,
            noteDisplacements: this.noteDisplacements,
            noteInstallationData: this.noteInstallationData,
            noteLoadCaseResultingAnchorForces: this.noteLoadCaseResultingAnchorForces,
            noteShearLoad: this.noteShearLoad,
            noteTensionLoad: this.noteTensionLoad,
            reportPaperSize: this.exportReportCompanyLayoutInputs.paperSizeDropdown.selectedValue ?? PaperSize.A4,
            reportType: this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue ?? ReportType.Detailed,
            trimbleConnectFolderId: this.exportTrimbleConnectInput.trimbleConnectFolderId,
            trimbleConnectLocation: this.exportTrimbleConnectInput.trimbleConnectLocation,
            trimbleConnectReportName: this.exportTrimbleConnectInput.trimbleConnectReportName,
            trimbleConnectUpload: this.exportTrimbleConnectInput.trimbleConnectChecked
        };
    }

    private async generateAndDownloadReportCW() {
        this.design.screenshots.reportLoadsLegend = await this.generateLoadsLegendImage();
        this.updateReportSettings();

        const options = this.getReportOptions();

        await this.design.addModelChange(PropertyMetaData.Option_CW_CustomPictures.id, true, [...this.customPictureIds]);

        await this.calculationService.generateAndDownloadReport(this.design, options)
            .then(() => this.close())
            .catch(error => {
                this.submitted = false;

                throw error;
            });
    }

    private updateReportSettings() {
        // details
        this.design.projectDesign.options.reportDialog.notes = this.exportReportProjectDetailsInputs.notes;
        this.design.projectDesign.options.reportDialog.fasteningPoint = this.exportReportProjectDetailsInputs.fasteningPoint;

        // custom comments
        this.design.projectDesign.options.reportDialog.noteLoadCaseResultingRebarForces = this.noteLoadCaseResultingAnchorForces;
        this.design.projectDesign.options.reportDialog.noteTensionLoad = this.noteTensionLoad;
        this.design.projectDesign.options.reportDialog.noteShearLoad = this.noteShearLoad;
        this.design.projectDesign.options.reportDialog.noteCombinedTensionAndShearLoads = this.noteCombinedTensionAndShearLoad;
        this.design.projectDesign.options.reportDialog.noteDisplacements = this.noteDisplacements;
        this.design.projectDesign.options.reportDialog.noteInstallationData = this.noteInstallationData;

        // layout
        this.updateReportSettingsForTemplate();

        // trimble connect
        this.design.projectDesign.options.reportDialog.trimbleConnectUpload = this.exportTrimbleConnectInput.trimbleConnectChecked;
        if (this.design.projectDesign.options.reportDialog.trimbleConnectUpload) {
            this.design.projectDesign.options.reportDialog.trimbleConnectFolderId = this.exportTrimbleConnectInput.trimbleConnectFolderId;
            this.design.projectDesign.options.reportDialog.trimbleConnectLocation = this.exportTrimbleConnectInput.trimbleConnectLocation;
            this.design.projectDesign.options.reportDialog.trimbleConnectReportName = this.exportTrimbleConnectInput.trimbleConnectReportName;
        }
    }

    private updateReportSettingsForTemplate() {
        if (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId) {
            this.design.projectDesign.options.reportDialog.reportTemplateId = 0;
            this.design.projectDesign.options.reportDialog.reportCompanyName = this.exportReportCompanyLayoutInputs.reportCompanyName;
            this.design.projectDesign.options.reportDialog.reportAddress = this.exportReportCompanyLayoutInputs.reportAddress;
            this.design.projectDesign.options.reportDialog.reportContactPerson = this.exportReportCompanyLayoutInputs.reportContactPerson;
            this.design.projectDesign.options.reportDialog.reportPhoneNumber = this.exportReportCompanyLayoutInputs.reportPhoneNumber;
            this.design.projectDesign.options.reportDialog.reportEmail = this.exportReportCompanyLayoutInputs.reportEmail;
            this.design.projectDesign.options.reportDialog.reportType = this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue ?? ReportType.Detailed;
            this.design.projectDesign.options.reportDialog.reportPaperSize = this.exportReportCompanyLayoutInputs.paperSizeDropdown.selectedValue ?? PaperSize.A4;
        }
        else {
            this.design.projectDesign.options.reportDialog.reportTemplateId = this.exportReportCompanyLayoutInputs.reportTemplate?.Id as number;
            this.design.projectDesign.options.reportDialog.reportCompanyName = this.exportReportCompanyLayoutInputs.reportTemplate?.Company ?? '';
            this.design.projectDesign.options.reportDialog.reportAddress = this.exportReportCompanyLayoutInputs.reportTemplate?.Address ?? '';
            this.design.projectDesign.options.reportDialog.reportContactPerson = this.exportReportCompanyLayoutInputs.reportTemplate?.ContactPerson ?? '';
            this.design.projectDesign.options.reportDialog.reportPhoneNumber = this.exportReportCompanyLayoutInputs.reportTemplate?.PhoneNumber ?? '';
            this.design.projectDesign.options.reportDialog.reportEmail = this.exportReportCompanyLayoutInputs.reportTemplate?.Email ?? '';
            this.design.projectDesign.options.reportDialog.reportType = (this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType ?? ReportType.Detailed) as number;
            this.design.projectDesign.options.reportDialog.reportPaperSize = this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize ?? PaperSize.A4 as number;
        }

        this.design.projectDesign.options.reportDialog.reportLanguageId = this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number;
        this.design.projectDesign.options.reportDialog.firstPageNumber = this.exportReportCompanyLayoutInputs.firstPageNumber;
    }

    private async generateLoadsLegendImage(): Promise<string> {
        const loadsLegendType = this.design.loadsLegendType;
        const reportLanguage = this.languages.find(l => l.id === this.getSelectedLanguageId());

        const legendGenerator = new LegendImageGenerator({
            hasLoads: loadsLegendType != LoadsLegendType.None,
            loadsLegendType: loadsLegendType,
            culture: reportLanguage?.culture ?? '',
            hasLoadsInWorstPosition: false, // False for now
            hasCharacteristicAndSustained: false, // False for now, should be enabled for EN design standards
            localizationService: this.localizationService,
            scale: 1,
            loadIconBlueImage: loadIconBlueImage,
            loadIconLoadInWorstPositionImage: loadIconLoadInWorstPositionImage,
            loadIconOrangeImage: loadIconOrangeImage,
            loadIconImage: loadIconImage
        });

        return await legendGenerator.generateLegendImage();
    }

    private getSelectedLanguageId() {
        return this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue ?? this.userSettingsService.settings.application.general.languageId.value;
    }

    private setIncludeDetailsItems(): void {
        this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push({
            id: 'export-report-details-section-item-number-checkbox',
            text: this.translate('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeItemNumber'),
            value: ReportDetails.IncludeItemNumbers,
            disabled: this.includeItemNumbersDisabled()
        });

        if (this.canIncludeApprovals()) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push({
                id: 'include-approvals-checkbox',
                text: this.translate('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeApprovals'),
                value: ReportDetails.IncludeApprovals,
                disabled: this.includeApprovalDisabled()
            });
        }
    }

    private includeItemNumbersDisabled(): boolean {
        return this.design.designData.projectDesign?.product.anchorChannel.itemNumber == null &&
            this.design.designData.projectDesign?.basePlate.bolt.itemNumber == null;
    }

    private includeApprovalDisabled(): boolean {
        return (this.design.reportData?.approvals?.length ?? 0) == 0;
    }

    private canIncludeApprovals(): boolean {
        return !this.design.isPostInstalledAnchorSelected;
    }

    private uploadPictures(): Promise<string[]> {
        return this.calculationService.uploadImages(this.design.id, this.getDesignCustomPictures());
    }

    private getDesignCustomPictures(): string[] {
        const map: string[] = [];
        for (const [key, value] of this.customPictures) {
            map[key - 1] = value.imgUrl;
        }
        return map;
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Image creation related functions
    public removeCustomPicture(index: number) {
        if (this.submitted) {
            return;
        }
        if (this.customPictures.has(index)) {
            this.customPicturesUploadSize += this.customPictures?.get(index)?.imgSize ?? 0;
            this.customPictures.delete(index);
            this.customPicturesChanged = true;
        }

        if (this.customPictures.size == 0) {
            this.customPictureIds = [];
        }
    }

    public customPictureSelected(index: number) {
        switch (index) {
            case 1: {
                if (this.customPicturesInput1.value != null && this.customPicturesInput1.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput1.files as FileList)[0], index);
                    this.customPicturesInput1.value = '';
                }
                break;
            }
            case 2: {
                if (this.customPicturesInput2.value != null && this.customPicturesInput2.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput2.files as FileList)[0], index);
                    this.customPicturesInput2.value = '';
                }
                break;
            }
            case 3: {
                if (this.customPicturesInput3.value != null && this.customPicturesInput3.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput3.files as FileList)[0], index);
                    this.customPicturesInput3.value = '';
                }
                break;
            }
        }
    }

    public selectCustomPicture(index: number) {
        switch (index) {
            case 1: {
                if (this.customPicturesInput1.value == null || this.customPicturesInput1.value == '') {
                    this.customPicturesInput1.click();
                }
                break;
            }
            case 2: {
                if (this.customPicturesInput2.value == null || this.customPicturesInput2.value == '') {
                    this.customPicturesInput2.click();
                }
                break;
            }
            case 3: {
                if (this.customPicturesInput3.value == null || this.customPicturesInput3.value == '') {
                    this.customPicturesInput3.click();
                }
            }
        }
    }

    private uploadCustomPicture(file: File, index: number) {
        if (file.type != 'image/png' && file.type != 'image/jpeg') {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.WrongFileTypeAlert.Title'),
                this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.WrongFileTypeAlert.Message')
            );
            return;
        }
        if (file.size > this.customPicturesUploadSize) {
            if (this.customPictures.size < 1) {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.FileTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.FileTooLargeAlert.Message')
                );
                return;
            }
            else {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.FilesTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.FilesTooLargeAlert.Message')
                );
                return;
            }
        }

        const reader = new FileReader();

        // ngZone.run() as replacement for this.$scope.$apply in angularJS
        reader.addEventListener('load', () => {
            const img: HTMLImageElement = new ((window as any).Image)();
            img.src = reader.result as string;
            this.ngZone.run(() => {
                img.addEventListener('load', () => {
                    this.ngZone.run(() => {
                        if (img.naturalWidth > 1200 || img.naturalHeight > 800) {
                            this.modalService.openAlertWarning(
                                this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.ImageTooLargeAlert.Title'),
                                this.localizationService.getString('Agito.Hilti.CW.ReportTemplates.ImageTooLargeAlert.Message')
                            );
                            return;
                        }
                        this.customPicturesUploadSize -= file.size;
                        this.customPictures.set(index, { imgUrl: img.src, imgSize: file.size });
                        this.customPicturesChanged = true;
                    });
                }, false);
            });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////

    private setCustomPictures(): void {
        this.customPictureIds = this.design.customPictures ?? [];
        this.calculationService.getCustomImages(this.design.customPictures)
            .then(images => {
                for (let i = 0; i < images.length; i++) {
                    const image = images[i];
                    const imageSize = this.getImageSize(image);
                    this.customPictures.set(i + 1, { imgUrl: image, imgSize: imageSize });
                }
            });
    }

    private getImageSize(value: string): number {
        // The size of the decoded data can be approximated with this formula.
        return 4 * Math.ceil(((value.length - 'data:image/png;base64,'.length) / 3)) * 0.5624896334383812;
    }
}
