import {
    Tooltip
} from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { GuidService } from '@profis-engineering/pe-ui-common/services/guid.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import { Design } from '../entities/design';
import { LoadCombinationEntity } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.Design';
import { UIProperty } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities.UIProperties';
import { LoadTypes } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Enums';
import { PropertyMetaData } from '../entities/properties';
import { CodeListService } from '../services/code-list.service';
import { ModalService } from '../services/modal.service';
import { TranslationFormat } from '../entities/generated-modules/Hilti.CW.CalculationService.Shared.Entities';
import { TranslationFormatService } from '../services/translation-format.service';
import { Constants } from '../entities/constants';
import { DesignCodeList } from '../entities/enums/design-code-list';

export interface LoadCombinationColumnProps<T> {
    name: T;
    forceVx: T;
    forceVy: T;
    forceN: T;
    momentMx: T;
    momentMy: T;
    momentMz: T;
    utilization: T;
}

export interface MousePosition {
    x: number;
    y: number;
}

export class LoadsComponentHelper {

    public static readonly minResizerColumnWidth: LoadCombinationColumnProps<number> = {
        name: 50,
        forceVx: 50,
        forceVy: 50,
        forceN: 50,
        momentMx: 50,
        momentMy: 50,
        momentMz: 50,
        utilization: 55,
    };

    public loadTypeEnum = {
        Static: LoadTypes.Static,
        Seismic: LoadTypes.Seismic,
        Dynamic: LoadTypes.Dynamic,
        Fire: LoadTypes.Fire,
    };

    private static readonly typeButtonWidth: number = 32; // sync with css variable @type-button-width

    // Column Resizing
    public resizerColumnWidth?: LoadCombinationColumnProps<number> | null;
    public resizerOverlayElement!: HTMLElement;
    public resizerTriggerPosition?: MousePosition;
    public resizerTriggerElement!: HTMLElement;

    public isUtilizationCompact = false;

    // Load
    private _addingNewLoad = false;
    public newLoad!: LoadCombinationEntity;

    public staticTooltip!: Tooltip;
    public seismicTooltip!: Tooltip;
    public fireTooltip!: Tooltip;
    public fatigueTooltip!: Tooltip;

    public forceXRange?: IValueRange;
    public forceYRange?: IValueRange;
    public forceZRange?: IValueRange;
    public momentXRange?: IValueRange;
    public momentYRange?: IValueRange;
    public momentZRange?: IValueRange;
    public dynamicForceZRange?: IValueRange;
    public dynamicMomentXRange?: IValueRange;
    public dynamicMomentYRange?: IValueRange;

    public headerFlexValue = 0.7;

    private columnVisibilitiesStore!: LoadCombinationColumnProps<boolean>;

    private guid: GuidService;

    constructor(
        private localizationService: LocalizationServiceBase,
        private codeListService: CodeListService,
        private unitService: UnitServiceBase,
        private modalService: ModalService,
        private translationFormatService: TranslationFormatService,
        public design: Design
    ) {
        this.guid = new GuidService();
    }

    public get columnTypeWidth() {
        const count = (this.isLoadTypeAllowed(LoadTypes.Static) ? 1 : 0) +
            (this.isLoadTypeAllowed(LoadTypes.Seismic) ? 1 : 0) +
            (this.isLoadTypeAllowed(LoadTypes.Dynamic) ? 1 : 0) +
            (this.isLoadTypeAllowed(LoadTypes.Fire) ? 1 : 0);

        return (count * LoadsComponentHelper.typeButtonWidth + 1) + 1; // +1 add left border for each button and +1 add right border for the last button
    }

    public get isPostInstallAnchorProduct() {
        return this.design.isPostInstallAnchorProduct();
    }

    public get showColumnMoments() {
        return this.columnVisibilities.momentMx || this.columnVisibilities.momentMy || this.columnVisibilities.momentMz;
    }

    public get columnMomentsClass(): string {
        let counter = 0;
        if (this.columnVisibilities.momentMx) counter += 1;
        if (this.columnVisibilities.momentMy) counter += 1;
        if (this.columnVisibilities.momentMz) counter += 1;
        return 'column-moments-' + counter;
    }

    public get columnMomentsWidth() {
        if (this.resizerColumnWidth == undefined)
            return undefined;

        const momentMxWidth = this.columnVisibilities.momentMx ? this.resizerColumnWidth?.momentMx ?? 0 : 0;
        const momentMyWidth = this.columnVisibilities.momentMy ? this.resizerColumnWidth?.momentMy ?? 0 : 0;
        const momentMzWidth = this.columnVisibilities.momentMz ? this.resizerColumnWidth?.momentMz ?? 0 : 0;

        return momentMxWidth + momentMyWidth + momentMzWidth;
    }

    public get showColumnForces() {
        return this.columnVisibilities.forceVx || this.columnVisibilities.forceVy || this.columnVisibilities.forceN;
    }

    public get columnForcesClass(): string {
        let counter = 0;
        if (this.columnVisibilities.forceVx) counter += 1;
        if (this.columnVisibilities.forceVy) counter += 1;
        if (this.columnVisibilities.forceN) counter += 1;
        return 'column-forces-' + counter;
    }

    public get columnForcesWidth() {
        if (this.resizerColumnWidth == undefined)
            return undefined;

        const forceMxWidth = this.columnVisibilities.forceVx ? this.resizerColumnWidth?.forceVx ?? 0 : 0;
        const forceMyWidth = this.columnVisibilities.forceVy ? this.resizerColumnWidth?.forceVy ?? 0 : 0;
        const forceNWidth = this.columnVisibilities.forceN ? this.resizerColumnWidth?.forceN ?? 0 : 0;

        return forceMxWidth + forceMyWidth + forceNWidth;
    }

    public get showColumnUtilization() {
        return this.columnVisibilities.utilization;
    }

    public initialize() {
        // Load type tooltips
        this.setLoadTypeTooltips();

        // Resizing
        this.resizerOverlayElement = document.createElement('div');
        this.resizerOverlayElement.setAttribute('id', 'resize-drag-handle-vertical');

        // New load
        this.initializeNewLoad();

        // Load value ranges
        this.loadValueRanges();

        this.columnVisibilitiesStore = this.columnVisibilities;
    }

    public setLoadTypeTooltips() {
        const designStandard = this.design.designStandard?.displayKey ?? '';

        this.staticTooltip = {
            title: this.translate('Agito.Hilti.CW.LoadType.Static.Tooltip.Title'),
            content: this.translate(this.loadTypeToolTip(LoadTypes.Static, designStandard))
        };

        this.seismicTooltip = {
            title: this.translate('Agito.Hilti.CW.LoadType.Seismic.Tooltip.Title'),
            content: this.translate(this.loadTypeToolTip(LoadTypes.Seismic, designStandard))
        };

        this.fatigueTooltip = {
            title: this.translate('Agito.Hilti.CW.LoadType.Fatigue.Tooltip.Title'),
            content: this.translate(this.loadTypeToolTip(LoadTypes.Dynamic, designStandard))
        };

        this.fireTooltip = {
            title: this.translate('Agito.Hilti.CW.LoadType.Fire.Tooltip.Title'),
            content: this.translate(this.loadTypeToolTip(LoadTypes.Fire, designStandard))
        };
    }

    private loadValueRanges() {
        this.forceXRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_ForceX.id);
        this.forceYRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_ForceY.id);
        this.forceZRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_ForceZ.id);
        this.momentXRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_MomentX.id);
        this.momentYRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_MomentY.id);
        this.momentZRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_MomentZ.id);
        this.dynamicForceZRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_SustainedForceZ.id);
        this.dynamicMomentXRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_SustainedMomentX.id);
        this.dynamicMomentYRange = this.getLoadsRange(PropertyMetaData.LoadCombination_CW_SustainedMomentY.id);
    }

    private getLoadsRange(uiProperty: UIProperty): IValueRange {

        const propertyValue = this.codeListService.getPropertyValue(uiProperty, this.design.region.id ?? -1);

        return {
            max: propertyValue?.maxValue,
            min: propertyValue?.minValue
        };
    }

    public get columnVisibilities(): LoadCombinationColumnProps<boolean> {
        return {
            name: true,
            forceVx: !this.isPropertyHidden(UIProperty.LoadCombination_CW_ForceX),
            forceVy: !this.isPropertyHidden(UIProperty.LoadCombination_CW_ForceY),
            forceN: !this.isPropertyHidden(UIProperty.LoadCombination_CW_ForceZ),
            momentMx: !this.isPropertyHidden(UIProperty.LoadCombination_CW_MomentX),
            momentMy: !this.isPropertyHidden(UIProperty.LoadCombination_CW_MomentY),
            momentMz: !this.isPropertyHidden(UIProperty.LoadCombination_CW_MomentZ),
            utilization: true,
        };
    }

    public getLoadTypeAsString(value: number): string {
        switch (value) {
            case LoadTypes.None:
                return 'None';
            case LoadTypes.Static:
                return 'Static';
            case LoadTypes.Seismic:
                return 'Seismic';
            case LoadTypes.Dynamic:
                return 'Fatigue';
            case LoadTypes.Fire:
                return 'Fire';
            default:
                return 'Unknown Load Type';
        }
    }

    public isLoadTypeAllowed(loadType: LoadTypes) {
        const { allowedValues } = this.design.properties.get(PropertyMetaData.Load_CW_LoadType.id);

        if (allowedValues == null) {
            return false;
        }

        return allowedValues.includes(loadType);
    }

    public loadTypeToolTip(loadType: LoadTypes, designStandardKey?: string): string {
        const loadDisabled = this.isLoadTypeDisabled(loadType);
        const tooltipItem = this.design.designData.designCodeLists[DesignCodeList.LoadTypes].find(l => l.id === loadType);
        const designStandardName = designStandardKey ? `.${designStandardKey}` : '';

        if (loadDisabled)
            return tooltipItem?.tooltipDisabledDisplayKey ?? '';

        return tooltipItem?.tooltipDisplayKey?.endsWith(designStandardName)
            ? tooltipItem?.tooltipDisplayKey
            : `${tooltipItem?.tooltipDisplayKey}${designStandardName}`;
    }

    public isLoadTypeDisabled(loadType: LoadTypes): boolean {
        const { allowedValues, disabledValues } = this.design.properties.get(PropertyMetaData.Load_CW_LoadType.id);

        const disabled = (allowedValues != null && !allowedValues.includes(loadType)) ||
               (disabledValues != null && disabledValues.includes(loadType));

        return disabled;
    }

    public get loads(): LoadCombinationEntity[] {
        return this.design.designData.projectDesign?.loads.loadCombinations ?? [];
    }

    public get maxLoads() {
        return Constants.MaxLoadCombinations;
    }

    public get addingNewLoad() {
        return this._addingNewLoad;
    }

    public set addingNewLoad(value: boolean) {
        this._addingNewLoad = value;
    }

    public getSizeFlexOrNull(size: number | undefined): string | null {
        return size != null ? size + 'px' : null;
    }

    public getPrecision(unit: Unit, uiProperty: UIProperty) {
        return this.unitService.getPrecision(unit, uiProperty);
    }

    public initializeNewLoad() {
        this.resetNewLoad();
    }

    private resetNewLoad() {
        this.newLoad = {
            id: this.guid.new(),
            name: '',
            description: '',
            loadType: LoadTypes.Static,
            forceX: 0,
            forceY: 0,
            forceZ: 0,
            momentX: 0,
            momentY: 0,
            momentZ: 0,
            dynamicForceZ: 0,
            dynamicMomentX: 0,
            dynamicMomentY: 0,
            hasPreCalculationScopeChecksError: false,
            hasPostCalculationScopeChecksError: false,
            hasScopeChecksError: false,
            resultMessages: [],
            isWizardGenerated: false,
            hasSustainedLoads: this.hasSustainedLoads,
        };
    }

    public get hasSustainedLoads() {
        return this.loads.some(load => load.hasSustainedLoads);
    }

    public get forceUnit() {
        return this.unitService.getDefaultUnit(UnitGroup.Force);
    }

    public get momentUnit() {
        return this.unitService.getDefaultUnit(UnitGroup.Moment);
    }

    public isPropertyDisabled(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.disabled : true;
    }

    public isPropertyHidden(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.hidden : true;
    }

    public isDoubleHeight(load?: LoadCombinationEntity): boolean {
        if (load === undefined)
            return false;

        return load?.hasSustainedLoads;
    }

    public setCompactCollapsed(collapsed: boolean) {
        if (collapsed) {
            // Loads collapsed --> compact = true
            this.isUtilizationCompact = true;
            return true;
        }

        return false;
    }

    public calculateCompact(utilizationElement?: HTMLElement) {
        this.isUtilizationCompact = (utilizationElement?.offsetWidth ?? 0) < 210;
    }

    public beginColumnResizing(mouse: MousePosition, resizerTriggerElement: HTMLElement) {
        this.resizerOverlayElement.style.top = '0';
        this.resizerOverlayElement.style.bottom = '0';
        this.resizerOverlayElement.style.left = `${mouse.x}px`;

        this.resizerTriggerPosition = mouse;
        this.resizerTriggerElement = resizerTriggerElement;
    }

    public continueColumnResizing(mouse: MousePosition) {
        this.resizerOverlayElement.style.left = `${mouse.x}px`;
    }

    public endColumnResizing(mouse: MousePosition) {
        const diffMouse = {
            x: mouse.x - (this.resizerTriggerPosition?.x ?? 0),
            y: mouse.y - (this.resizerTriggerPosition?.y ?? 0)
        };

        this.resizeColumns(this.resizerTriggerElement, diffMouse.x);
    }

    public calculateMouseInsideElement(event: MouseEvent, element: HTMLElement): MousePosition {
        return {
            x: event.pageX - (element.getBoundingClientRect().left + (window.pageXOffset || document.documentElement.scrollLeft)),
            y: event.pageY - (element.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop))
        };
    }

    private resizeColumns(resizer: HTMLElement, sizeDifference: number) {
        if (resizer == null) {
            resizer = document.querySelector<HTMLElement>('.resizer-left') as HTMLElement;
        }

        let column: HTMLElement | undefined = resizer.closest<HTMLElement>('.resizer-column') as HTMLElement;
        const row = resizer.closest<HTMLElement>('.resizer-row') as HTMLElement;

        this.setResizerColumnProps(row);
        const resizerColumnWidth = this.resizerColumnWidth as LoadCombinationColumnProps<number>;


        const allColumns = Array.from(column.parentElement?.children ?? []) as HTMLElement[];
        const resizableColumns = allColumns.filter(col => col.classList.contains('resizer-column'));
        const columnIndex = resizableColumns.indexOf(column);

        const nextColumns = resizableColumns.slice(columnIndex + 1);
        const prevColumns = resizableColumns.slice(0, columnIndex).reverse(); // reverse previous columns, as we want to prioritize them from the closest one to the first one

        if (sizeDifference > 0) { // mouse move to right means that next columns should narrow
            if (resizer.classList.contains('resizer-left')) {
                nextColumns.unshift(column); // make column that has resizer next column and previous column as the one being resized
                column = prevColumns.shift();
            }

            const columnProp = this.mapResizerColumnClassToProperty(column);
            const columnPropKey = columnProp as keyof LoadCombinationColumnProps<number>;

            for (const nextColumn of nextColumns) {
                if (sizeDifference <= 0) {
                    break;
                }

                const nextColumnProp = this.mapResizerColumnClassToProperty(nextColumn);
                const nextColumnPropKey = nextColumnProp as keyof LoadCombinationColumnProps<number>;

                const minColumnWidth = this.getMinResizerColumnWidth(nextColumnPropKey);

                const availableSize = Math.max(resizerColumnWidth[nextColumnPropKey] - minColumnWidth, 0);
                const applicableSize = Math.min(availableSize, sizeDifference);

                resizerColumnWidth[columnPropKey] += applicableSize;
                resizerColumnWidth[nextColumnPropKey] -= applicableSize;
                sizeDifference -= applicableSize;
            }
        }
        else { // mouse move to left means that previous columns should narrow
            if (resizer.classList.contains('resizer-right')) {
                prevColumns.unshift(column); // make column that has resizer previous column and next column as the one being resized
                column = nextColumns.shift();
            }

            if (column !== undefined) {
                const columnProp = this.mapResizerColumnClassToProperty(column);
                const columnPropKey = columnProp as keyof LoadCombinationColumnProps<number>;

                sizeDifference = Math.abs(sizeDifference);
                for (const prevColumn of prevColumns) {
                    if (sizeDifference <= 0) {
                        break;
                    }

                    const prevColumnProp = this.mapResizerColumnClassToProperty(prevColumn);
                    const prevColumnPropKey = prevColumnProp as keyof LoadCombinationColumnProps<number>;

                    const minColumnWidth = this.getMinResizerColumnWidth(prevColumnPropKey);

                    const availableSize = Math.max(resizerColumnWidth[prevColumnPropKey] - minColumnWidth, 0);
                    const applicableSize = Math.min(availableSize, sizeDifference);

                    resizerColumnWidth[columnPropKey] += applicableSize;
                    resizerColumnWidth[prevColumnPropKey] -= applicableSize;
                    sizeDifference -= applicableSize;
                }
            }
        }
    }

    private setResizerColumnProps(row: HTMLElement) {
        this.resizerColumnWidth = {
            name: row?.querySelector<HTMLElement>('.column-name')?.offsetWidth || 0,
            forceVx: row?.querySelector<HTMLElement>('.column-vx')?.offsetWidth || 0,
            forceVy: row?.querySelector<HTMLElement>('.column-vy')?.offsetWidth || 0,
            forceN: row?.querySelector<HTMLElement>('.column-n')?.offsetWidth || 0,
            momentMx: row?.querySelector<HTMLElement>('.column-mx')?.offsetWidth || 0,
            momentMy: row?.querySelector<HTMLElement>('.column-my')?.offsetWidth || 0,
            momentMz: row?.querySelector<HTMLElement>('.column-mz')?.offsetWidth || 0,
            utilization: row?.querySelector<HTMLElement>('.column-utilization')?.offsetWidth || 0
        };
    }

    private mapResizerColumnClassToProperty(col: HTMLElement | undefined) {
        // finds property name from column class

        const propertyNames: { [key: string]: string } = {
            'column-name': 'name',
            'column-vx': 'forceVx',
            'column-vy': 'forceVy',
            'column-n': 'forceN',
            'column-mx': 'momentMx',
            'column-my': 'momentMy',
            'column-mz': 'momentMz',
            'column-utilization': 'utilization',
            'column-utilization-pi': 'utilization',
        };

        for (const key in propertyNames) {
            if (col?.classList.contains(key)) {
                return propertyNames[key];
            }
        }

        throw new Error('Unknown column class.');
    }

    private getMinResizerColumnWidth(property: keyof LoadCombinationColumnProps<number>): number {
        return LoadsComponentHelper.minResizerColumnWidth[property];
    }

    public formatPercentage(valueStr?: string): string {
        if (valueStr == null)
            return '';

        const value = +valueStr;
        switch (value) {
            case undefined:
            case null:
                return '';
            case Number.NEGATIVE_INFINITY:
                return '-∞ %';
            case Number.POSITIVE_INFINITY:
                return '∞ %';
            default:
                return this.unitService.formatNumber(value, 2) + '%';
        }
    }

    public get haveSingleLoad() {
        return this.loads == null || this.loads.length <= 1;
    }

    public hasMessages(load: LoadCombinationEntity) {
        if (load == null || load.resultMessages == null) {
            return false;
        }

        return load.resultMessages.length > 0;
    }

    public showMessages(load: LoadCombinationEntity) {
        this.modalService.openLoadsMessages(this.getScopeCheckMessages(load.resultMessages));
    }

    private getScopeCheckMessages(scopeCheckMessages: TranslationFormat[]): string[] {
        return scopeCheckMessages
            .map(msg => this.translationFormatService.getLocalizedStringWithTranslationFormat(msg) ?? '')
            .filter(msg => msg != '');
    }

    public loadLoadTypeToggleItems(parentId: string, loadIndex: number, loadImages: Map<LoadTypes, IIconStyle>) {
        const loadTypeToggleItems: ToggleButtonGroupItem<LoadTypes>[] = [];

        let type = LoadTypes.Static;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<LoadTypes> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-shock',
                tooltip: this.staticTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = LoadTypes.Seismic;
        if (this.isLoadTypeAllowed(type))
            {
            const item: ToggleButtonGroupItem<LoadTypes> = {
                id: '',
                value: type,
                disabled: this.isLoadTypeDisabled(type),
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-seismic',
                tooltip: this.seismicTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = LoadTypes.Dynamic;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<LoadTypes> = {
                id: '',
                value: type,
                disabled: this.isLoadTypeDisabled(type),
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-fatigue',
                tooltip: this.fatigueTooltip

            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = LoadTypes.Fire;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<LoadTypes> = {
                id: '',
                value: type,
                disabled: this.isLoadTypeDisabled(type),
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-fire-resistant',
                tooltip: this.fireTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        return loadTypeToggleItems;
    }

    public setLoadTypeToggleItemId(item: ToggleButtonGroupItem<LoadTypes>, parentId: string, loadIndex: number) {
        switch (item.value) {
            case LoadTypes.Static:
                item.id = `${loadIndex != null ? 'LC-table-static-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-static-loadtype-button'}`;
                break;

            case LoadTypes.Seismic:
                item.id = `${loadIndex != null ? 'LC-table-seismic-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-seismic-loadtype-button'}`;
                break;

            case LoadTypes.Dynamic:
                item.id = `${loadIndex != null ? 'LC-table-fatigue-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-fatigue-loadtype-button'}`;
                break;

            case LoadTypes.Fire:
                item.id = `${loadIndex != null ? 'LC-table-fire-loadtype-button-' + loadIndex : 'LC-table-add-new-combination-fire-loadtype-button'}`;
                break;
        }
        item.disabled = this.isLoadTypeDisabled(item.value as LoadTypes);
        item.tooltip = this.translate(this.loadTypeToolTip(item.value as LoadTypes, this.design.designStandard?.displayKey ?? ''));
    }

    public get firstRowLoadLegendTooltip() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads.Design.Tooltip');
    }

    public get firstRowLoadLegendTranslation() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads.Design');
    }

    public get secondRowLoadLegendTooltip() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads.Sustained.Tooltip');
    }

    public get secondRowLoadLegendTranslation() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads.Sustained');
    }

    public resetAllColumnsWidth() {
        if (Object.keys(this.columnVisibilitiesStore).some(prop => {
            const propKey = prop as keyof LoadCombinationColumnProps<boolean>;
            return this.columnVisibilitiesStore[propKey] != this.columnVisibilities[propKey];
        })) {
            setTimeout(() => {
                this.resizerColumnWidth = undefined;
            });
        }

        this.columnVisibilitiesStore = this.columnVisibilities;
    }

    public get isPerBolt(): boolean {
        return this.design.isPerBolt;
    }

    public get isMultipleBrackets(): boolean {
        return this.design.isMultipleBrackets;
    }

    public get isSelectingCombinationEnabled(): boolean {
        return !this.isPerBolt && !this.isMultipleBrackets;
    }

    public get isLoadTypeSelectingOnRowEnabled(): boolean {
        return !this.isPerBolt && !this.isMultipleBrackets;
    }

    public get isNameDisabled(): boolean {
        return this.isPerBolt || this.isMultipleBrackets;
    }

    public get isMultipleLoadCombinations(): boolean {
        return this.design.isMultipleLoadCombinations;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
